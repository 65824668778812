import { NftModel } from "./../models/nft-model";
import moment from "moment";
import web3 from "web3";

class LocalData {
  getNewMonsters(): NftModel[] {
    return JSON.parse(localStorage.getItem("newMonsters") || "[]");
  }
  setNewMonsters(value: NftModel[]) {
    localStorage.setItem("newMonsters", JSON.stringify(value));
  }
  clearNewMonsters() {
    localStorage.removeItem("newMonsters");
  }

  getNewMonster() {
    const accounts = JSON.parse(localStorage.getItem(`newMonster`) || "{}");
    return accounts;
  }
  setNewMonster(value: string[]) {
    localStorage.setItem(`newMonster`, JSON.stringify(value));
  }

  setMonsterNftIds(account, nftIds) {
    localStorage.setItem(`${account}-nftIds`, JSON.stringify(nftIds));
  }
  getMonsterNftIds(account) {
    const nftIds = JSON.parse(localStorage.getItem(`${account}-nftIds`) || "[]");
    return nftIds;
  }
}

export const localdata = new LocalData();
