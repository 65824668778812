import { walletStore } from "@/stores/wallet-store";
import { when } from "mobx";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/stake",
    name: "Staking",
    component: () => import("../modules/staking/pages/staking.vue"),
    meta: {
      title: "Demole Staking",
    },
  },
  {
    path: "/egg-hatch",
    name: "EggHatch",
    component: () => import("../modules/egg-hatch/pages/egg-hatch.vue"),
    meta: {
      title: "Egg Hatch",
    },
  },
  {
    path: "/egg-hatch-2",
    name: "egg-hatch-2",
    component: () => import("@/modules/egg-hatch-ticket/pages/egg-hatch-ticket.vue"),
    meta: {
      title: "Egg Hatch",
    },
  },
  {
    path: "/training-ground",
    name: "TrainingGround",
    component: () => import("../modules/training-ground/pages/training-ground.vue"),
    meta: {
      title: "Training Ground",
    },
  },
  // {
  //   path: "/summon",
  //   name: "Summon",
  //   component: () => import("@/modules/summon/pages/new-summon.vue"),
  // },
  // {
  //   path: "/summon",
  //   name: "Summon",
  //   component: () => import("@/modules/claim/pages/claim.vue"),
  // },
  // {
  //   path: "/market",
  //   name: "Market",
  //   component: () => import("@/modules/market/pages/market.vue"),
  // },
  {
    path: "/my-character",
    name: "MyCharacter",
    component: () => import("@/modules/my-character/pages/my-character.vue"),
    meta: {
      title: "My Character",
    },
  },
  {
    path: "/nft/:id",
    name: "CharacterDetail",
    component: () => import("@/modules/my-character/pages/character-detail.vue"),
    meta: {
      title: "Character Detail",
    },
  },
  {
    path: "/mystery-box",
    name: "Mystery Box",
    component: () => import("@/modules/mystery-box/pages/mystery-box.vue"),
    meta: {
      title: "Mystery Box",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  await when(() => walletStore.loaded);
  if (!to.name || !to) next("/my-character");
  else next();
});

function _setDocumentTitle(title = "Demole") {
  document.title = title;
}

router.afterEach((to) => {
  _setDocumentTitle(to?.meta?.title);
});

export default router;
