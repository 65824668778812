import Axios from "axios";
import { walletStore } from "@/stores/wallet-store";
import { NftModel } from "@/models/nft-model";
export type ApiRouteType = "nfts";

export class ApiHandler<T> {
  constructor(private axios, private route: ApiRouteType) {}

  async count(params?: any): Promise<number> {
    const res = await this.axios.get(`${this.route}/count`, { params });
    return res.data;
  }

  async create(model: T): Promise<T> {
    const res = await this.axios.post(this.route, model);
    return res.data;
  }

  async delete(id: any): Promise<T> {
    const res = await this.axios.delete(`${this.route}/${id}`);
    return res.data;
  }

  async find<T>(
    params?: any,
    settings: { _sort?: string; _limit?: number; _start?: number } = {}
  ): Promise<T[]> {
    const settingDefault = { _sort: "createdAt:DESC", _limit: 25, _start: 0 };
    params = { ...settingDefault, ...settings, ...(params ?? {}) };
    const res = await this.axios.get(this.route, { params });
    const lst = res.data;
    // if (this.route === "pool") {
    //   lst.forEach((element) => {
    //     if (element.data?.chainId) {
    //       element.chainId = element.data.chainId;
    //     } else if (isNaN(+element.chainId)) {
    //       element.chainId = +element.chainId;
    //     }
    //   });
    // }
    return lst;
  }

  async findOne<T>(id: any): Promise<T> {
    let res: any;
    if (id) {
      res = await this.axios.get(`${this.route}/${id}`);
    } else {
      res = await this.axios.get(`${this.route}`);
    }
    const result = res.data;
    // if (this.route === "pool") {
    //   if (result.data?.chainId) {
    //     result.chainId = result.data.chainId;
    //   } else if (isNaN(+result.chainId)) {
    //     result.chainId = +result.chainId;
    //   }
    // }
    return result;
  }

  async update(id: any, model: T): Promise<T> {
    let res: any;
    if (id) {
      res = await this.axios.put(`${this.route}/${id}`, model);
    } else {
      res = await this.axios.put(`${this.route}`, model);
    }
    return res.data;
  }
}

export class ApiService {
  axios = Axios.create({ baseURL: process.env.VUE_APP_API_ENDPOINT });

  nft = new ApiHandler<NftModel>(this.axios, "nfts");

  async getNFTs(params) {
    const res = await this.axios.get(`monster-nfts`, { params });
    return res.data;
  }
}

export const apiService = new ApiService();
