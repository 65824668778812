import { FixedNumber } from "@ethersproject/bignumber";

export const Zero = FixedNumber.from(0);
export const MonsterTypes = [
  {
    title: "Rare",
    value: 1,
  },
  {
    title: "Normal",
    value: 0,
  },
];
export const Classes = ["Water", "Fire", "Wood", "Metal", "Earth"];
// export const Races = ["Dragon", "Elf", "Water", "Ghost", "Wood"];
export const CustomRaces = [
  { text: "Dragon", value: "Dragon" },
  { text: "Angel", value: "Elf" },
  { text: "Merman", value: "Water" },
  { text: "Shadow", value: "Ghost" },
  { text: "Treant", value: "Wood" },
];
export const StakeBoosters = [
  {
    rare: 5,
    normal: 0,
    multiplier: 1.3,
  },
  {
    rare: 4,
    normal: 1,
    multiplier: 1.2,
  },
  {
    rare: 3,
    normal: 2,
    multiplier: 1.14,
  },
  {
    rare: 2,
    normal: 3,
    multiplier: 1.1,
  },
  {
    rare: 1,
    normal: 4,
    multiplier: 1.07,
  },
  {
    rare: 0,
    normal: 5,
    multiplier: 1.05,
  },
  {
    rare: 0,
    normal: 0,
    multiplier: 1,
  },
];
export const StakeLockPeriods = {
  "0": {
    lockPeriod: "No lock",
    value: 0,
    apr: 1.5,
  },
  "7": {
    lockPeriod: "7 days",
    value: 7,
    apr: 1.73,
  },
  "14": {
    lockPeriod: "14 days",
    value: 14,
    apr: 3.5,
  },
  "30": {
    lockPeriod: "30 days",
    value: 30,
    apr: 7,
  },
  "60": {
    lockPeriod: "60 days",
    value: 60,
    apr: 14,
  },
  "90": {
    lockPeriod: "90 days",
    value: 90,
    apr: 20,
  },
  "180": {
    lockPeriod: "180 days",
    value: 180,
    apr: 40,
  },
  "360": {
    lockPeriod: "360 days",
    value: 360,
    apr: 85,
  },
};
export const EventStakeLockPeriods = {
  "0": {
    lockPeriod: "No lock",
    value: 0,
    apr: 4.5,
  },
  "7": {
    lockPeriod: "7 days",
    value: 7,
    apr: 5.19,
  },
  "14": {
    lockPeriod: "14 days",
    value: 14,
    apr: 10.5,
  },
  "30": {
    lockPeriod: "30 days",
    value: 30,
    apr: 21,
  },
  "60": {
    lockPeriod: "60 days",
    value: 60,
    apr: 42,
  },
  "90": {
    lockPeriod: "90 days",
    value: 90,
    apr: 60,
  },
  "180": {
    lockPeriod: "180 days",
    value: 180,
    apr: 120,
  },
  "360": {
    lockPeriod: "360 days",
    value: 360,
    apr: 255,
  },
};
