import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    dark: true,
    themes: {
      dark: {
        primary: {
          base: "#FFB337",
          lighten1: "#FFF6E7",
        },
        accent: "#00CEFF",
        gray1: "#e0e0e0",
        background: "#121627",
        cardBackground: "#1A1E30",
        cardSubBackground: "#292f49",
        subtitle: "#9298AB",
        linkText: "#20BBC9",
      },
    },
  },
});
